.film-page {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }

  .film-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -3;
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -2;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .video-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
  }

  .movie-list {
    padding-top: 5vw;
    padding-left: 60vw;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: Abel, monospace;
    z-index: 1;
  }

  .movie-list a:active, .movie-list a:visited, .movie-list a:link{
    text-decoration: none;
    color: white;
  }

  .movie-list .movie-item {
    font-size: 3.5vw;
    font-family: Abel, monospace;
    padding: 0.2em;
  }

  .movie-list .movie-item:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  @media screen and (max-width: 1300px){
    .movie-list {
      padding-top: 20vh;
      padding-left: 20vw;
    }

    .movie-list .movie-item:hover {
      color: white;
    }

    .film-page {
      height: 100vh;
    }

    .film-image {
      height: 100vh;
    }

    .movie-list .movie-item {
      font-size: 3.5vh;
      padding-top: 2vh;
    }
  }