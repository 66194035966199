@font-face {
    font-family: 'Source Code Pro';
    src: url('../fonts/source_code_pro/SourceCodePro-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Abel';
    src: url('../fonts/abel/Abel-Regular.ttf') format('truetype');
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vw;
  }

  .modal {
    display: none;
  }
  
  .logo {
    color: white;
    font-family: Abel, monospace;
    transition: 0.3s, ease-in-out;
  }

  .logo-container {
    display: flex;
    font-size: 30px;
    align-items: center;
  }

  .logo-container * {
    margin: 10px;
  }
  
  .nav-items {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
  
  .nav-items li {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .nav-items li .link{
    color: white;
    text-decoration: none;
    font-family: Source Code Pro, monospace;
    font-size: 20px;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
  }

  .nav-items li .link:hover {
    text-underline-offset: 10px;
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }

  .nav-items li .link:active {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }

  .mobile-dropdown {
    display: none;
    padding-top: 6vh;
  }

  .mobile-dropdown:hover {
    display: block;
  }

  .dropdown {
    position: relative;
  }

  .dropdown:hover .link{
    z-index: 2;
  }

  .dropdown:hover .mobile-dropdown {
    display: block;
    position: absolute;
    background-color: white;
    color: black;
    margin-top: 29vh;
    margin-left: 0;
    font-family: Source Code Pro, monospace;
    font-size: 20px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    width: 220px;
    white-space: nowrap;
  }

  .dropdown-link-white {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 1.5vh 0vw 1.5vh 0vw;
    white-space: nowrap;
  }

  .dropdown-link-black {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 1.5vh 0vw 1.5vh 0vw;
    white-space: nowrap;
  }

  .dropdown-link-black:hover {
    background: rgb(230, 230, 230);
  }

  .dropdown-link-white:hover {
    background: rgb(60, 60, 60);
  }

  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    
    .logo-container {
      font-size: 2vw;
    }

    .logo {
      font-size: 2vw;
      width: 2vw;
      margin: 0.5vw;
    }

    .nav-items {
      display: none;
    }

    .nav-container .hamburger-menu {
      display: block;
      z-index: 2;
    }
  
    .bar {
      width: 4vw;
      height: 0.23vw;
      background-color: white;
      margin: 1.14vw;
      transition: 0.3s;
    }

    .hamburger-menu.active .bar:nth-child(1) {
      transform: rotate(45deg) translate(0.57vw, 0.57vw);
      background-color: black;
    }
  
    .hamburger-menu.active .bar:nth-child(2) {
      transform: rotate(-45deg) translate(0.46vw, -0.46vw);
      background-color: black;
    }

    .modal {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2;
      background-color: rgb(255,255,255);
      transform: translateY(100%);
      animation: slideUp 0.2s forwards;
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    
    .modal-items {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30vh;
    }

    .modal-items li {
      margin: 10px;
    }
    
    .modal-items li .link {
      color: rgb(0, 0, 0);
      text-decoration: none;
      font-family: Source Code Pro, monospace;
      font-size: 30px;
    }

    .modal-items li .link:hover {
      text-underline-offset: 10px;
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }

    @keyframes slideUp {
      0% {
        transform: translateY(20%);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  @media screen and (max-width: 1000px) {

    .logo-container {
      font-size: 3.5vw;
    }

    .logo {
      font-size: 3.5vw;
      width: 4vw;
      margin: 0.5vw;
    }

    .nav-items {
      display: none;
    }

    .nav-container .hamburger-menu {
      display: block;
      z-index: 2;
    }
  
    .bar {
      width: 4vw;
      height: 0.23vw;
      background-color: white;
      margin: 1.14vw;
      transition: 0.3s;
    }

    .hamburger-menu.active .bar:nth-child(1) {
      transform: rotate(45deg) translate(0.57vw, 0.57vw);
      background-color: black;
    }
  
    .hamburger-menu.active .bar:nth-child(2) {
      transform: rotate(-45deg) translate(0.46vw, -0.46vw);
      background-color: black;
    }

    .modal {
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2;
      background-color: rgb(255,255,255);
      transform: translateY(100%);
      animation: slideUp 0.2s forwards;
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    
    .modal-items {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30vh;
    }

    .modal-items li {
      margin: 10px;
    }
    
    .modal-items li .link {
      color: rgb(0, 0, 0);
      text-decoration: none;
      font-family: Source Code Pro, monospace;
      font-size: 30px;
    }

    .modal-items li .link:hover {
      text-underline-offset: 10px;
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }

    @keyframes slideUp {
      0% {
        transform: translateY(20%);
      }
      100% {
        transform: translateY(0);
      }
    }
  }