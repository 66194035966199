.image-container {
  background-color: black;
  display: flex;
  flex-direction: column;
  font-family: Abel, monospace;
  position: relative;
  padding-top: 8vh;
  padding-left: 10vw;
  height: 100%;
  width: 100%;
}

.title {
  display: flex;
  color: white;
  font-size: 3.5vh;
}