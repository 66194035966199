@font-face {
  font-family: 'Abel';
  src: url('../fonts/abel/Abel-Regular.ttf') format('truetype');
}

.photography-page {
  position: relative;
}

.photography-header {
  text-align: center;
  font-family: Abel, monospace;
  font-size: 3vw;
  color: black;
}

.photo-grid {
  margin-left: 8vw;
  margin-right: 8vw;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 800px) {
  .photography-header {
    font-size: 5vw;
  }
}