@font-face {
  font-family: 'Source Code Pro';
  src: url('../fonts/source_code_pro/SourceCodePro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Abel';
  src: url('../fonts/abel/Abel-Regular.ttf') format('truetype');
}

.image-gallery {
    display: flex;
    align-items: center;
  }
  
  .arrow {
    font-size: 24px;
    cursor: pointer;
  }
  
  .image-container {
    width: 100px;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  
  .image-item {
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: start;
    position: relative;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
  }
  
  .image-title {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-size: 18px;
  }

  .highlight {
    font-family: Abel, monospace;
    margin-left: 10vw;
    padding-top: 8vh;
    font-size: 3.5vh;
    margin-bottom: -17vh;
  }

  .card {
    height: 28vw;
    width: 70vw;
  }

  @media (max-width: 1200px){

    .highlight {
      margin-bottom: 0;
    }

    .card {
      margin-top: 10vw;
      height: 32vw;
      width: 80vw;
    }

    .mobile-view {
      display: flex;
      flex-direction: column;
      padding-top: 0vh;
      padding-bottom: 10vh;
      padding-left: 10vw;
    }
  }