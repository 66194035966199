.video-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    background-color: black;
  }
  
  .video-player iframe {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    margin-top: -6vh;
    margin-bottom: -100%;
  }

  @media screen and (max-width: 1000px) {
    .video-player {
      margin-top: -3vh;
    }

    .video-player iframe {
      margin-top: -5vh;
    }
  }